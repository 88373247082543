import React from 'react';
import Dataset from '../../../common/dataset/Dataset';

export default function GeneralDataset() {
  return (
    <Dataset
      editDatsetEndpoint="utils/retrieve-update-destroy-thc-dataset"
      createDatasetEndpoint={`utils/create-thc-dataset/`}
      importDatasetEndpoint={`utils/thc-admin-import-thc-dataset/`}
      deleteDatasetEndpoint={`utils/retrieve-update-destroy-thc-dataset`}
      clearDatasetEndpoint={`utils/thc-clear-dataset/`}
      url={`utils/get-thc-dataset`}
    />
  );
}
