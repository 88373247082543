import React from 'react';
import Dataset from '../common/dataset/Dataset';
import Layout from './layout';
import Header from '../common/Header';
import { useLocation } from 'react-router';

export default function FacilityDataset() {
  const { state } = useLocation();
  return (
    <Layout>
      <Header inner heading={`${state?.name} dataset`} />
      <Dataset
        editDatsetEndpoint
        createDatasetEndpoint={`main/pharmacy-inventory/thc-admin-create-dataset/${state.id}/`}
        importDatasetEndpoint={`main/pharmacy-inventory/thc-admin-import-dataset/${state.id}/`}
        deleteDatasetEndpoint={`main/pharmacy-inventory/retrieve-update-destroy-pharmacy-dataset`}
        clearDatasetEndpoint={`main/pharmacy-inventory/thc-admin-clear-facility-dataset/${state.id}/`}
        url={`main/pharmacy-inventory/thc-view-facility-dataset/${state.id}`}
      />
    </Layout>
  );
}
