import React, { useRef, useState, useEffect, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import '@styles/table.style.css';
import Pagination from '@utils/pagination';
import { serialNum, pageSummaryEnhanced } from '../../../utils/pageSummary';
import { PAGESIZE } from '../../../constants/pageSize';
import ClipLoader from 'react-spinners/ClipLoader';
import { FacilityTableStyle } from '../../facilityTableStyle';
import FacilityPopup from '../facilityPopup';
import ActivateDeactivateFacilityAccountModal from '../facilitySettingModal/activateDeactivateAccount';
import EnableDisableFacilityPaymentAccess from '../facilitySettingModal/enableDisableMobilePaymentAccess';
import Checkbox from '@components/formInputs/checkbox';
import { useFetchFacility } from '@hooks/queries';
import { RecordSubscriptiionPayment } from '../recordPaymentSubscription';
import ActivateFacilitySubscription from '../facilitySettingModal/activateSubscription';
import LockUnlock from '../facilitySettingModal/lockUnlock';

const pageSize = PAGESIZE;
export const FacilityTable = ({ facilityType }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItem, setCurrentItem] = useState('');
  const [activateModal, setActivateModal] = useState(false);
  const [paymentAccess, setPaymentAccess] = useState(false);
  const [paymentSubscription, setPaymentSubscription] = useState(false);
  const [activateSubscription, setActivateSubscription] = useState(false);
  const [lock, setLock] = useState(false);

  const { data: facilityData, isLoading } = useFetchFacility(facilityType);
  const data = facilityData?.data?.data?.data;
  const totalCount = data?.length;
  console.log('data', data);

  let startSeriaNum = serialNum(currentPage, pageSize);
  const [idOfTable, setIdOfTable] = useState(-1);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIdOfTable(-1);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const showModal = (index, item) => {
    setIdOfTable(index);
    setCurrentItem(item);
  };

  return (
    <FacilityTableStyle>
      {isLoading ? (
        <div className="spin-parent">
          <ClipLoader color="blue" size="40px" className="spinner" />
        </div>
      ) : (
        <div className="">
          <p className="mt-3">
            {pageSummaryEnhanced(startSeriaNum, totalCount, data)}
          </p>
          <table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>
                  <Checkbox type="checkbox" name="selectall" />
                </th>
                <th>Account no.</th>
                <th>Practice Name</th>
                <th>Email</th>
                <th>Facility is Active</th>
                <th>Facility is locked</th>
                <th>Subscription Category</th>
                <th>Date of first Registration</th>
                <th>Payment Mode</th>
                <th>Registration Validity</th>
                <th>Medical Reg. Valid</th>
                <th>Medical Reg. No</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data?.map((item, index) => (
                  <tr key={index}>
                    <td>{startSeriaNum++}</td>
                    <td>
                      <Checkbox type="checkbox" name={item?.id} />
                    </td>
                    <td>{item?.account_id}</td>
                    <td>
                      {item?.name
                        ? item?.name
                        : `${item?.first_name} ${item?.last_name}`}
                    </td>
                    <td>{item?.email}</td>
                    <th>{item?.activate_account ? 'YES' : 'NO'}</th>
                    <th>{item?.is_locked ? 'YES' : 'NO'}</th>
                    <td>{item?.subscription?.planName || ''}</td>
                    <td>{item?.created_at}</td>
                    <td>{item?.payment_mode}</td>
                    <td>{item?.subscription?.end_date || ''}</td>

                    <td>{item?.medical_registration_expiry_date || ''}</td>
                    <td>{item?.biz_reg_number}</td>
                    <td
                      id={index}
                      className="three-dots"
                      style={{ width: '9%', position: 'relative' }}
                      onClick={() => showModal(index, item)}
                    >
                      ...
                      {index === idOfTable ? (
                        <Fragment>
                          <span ref={ref} className="popup">
                            <FacilityPopup
                              activateSubscription={() =>
                                setActivateSubscription(!activateSubscription)
                              }
                              enablePaymentAccess={() =>
                                setPaymentAccess(!paymentAccess)
                              }
                              facilityInfo={currentItem}
                              importDataset={() => setIsModalOpen(!isModalOpen)}
                              activateAccount={() =>
                                setActivateModal(!activateModal)
                              }
                              facilityId={currentItem?.id}
                              currentName={currentItem?.name}
                              recordPayment={() =>
                                setPaymentSubscription(!paymentSubscription)
                              }
                              lockUnlockAccount={() => setLock(!lock)}
                            />
                          </span>
                        </Fragment>
                      ) : null}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>
                    <h4 className="title">No hospital found</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="pagination-container">
            {data && data.length > 0 && (
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={pageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            )}
          </div>
        </div>
      )}
      {activateModal && (
        <ActivateDeactivateFacilityAccountModal
          currentAccount={currentItem?.name}
          onClick={() => setActivateModal(!activateModal)}
          currentState={currentItem?.activate_account}
          facilityId={currentItem?.id}
        />
      )}
      {lock && (
        <LockUnlock
          currentAccount={currentItem?.name}
          onClick={() => setLock(!lock)}
          currentState={currentItem?.is_locked}
          facilityId={currentItem?.id}
        />
      )}
      {paymentAccess && (
        <EnableDisableFacilityPaymentAccess
          currentAccount={currentItem?.name}
          onClick={() => setPaymentAccess(!paymentAccess)}
          currentState={currentItem?.mobile_payment_access}
          facilityId={currentItem?.id}
        />
      )}
      {activateSubscription && (
        <ActivateFacilitySubscription
          subscription={currentItem?.subscription}
          currentAccount={currentItem?.name}
          onClick={() => setActivateSubscription(!activateSubscription)}
          currentState={currentItem?.subscription?.active}
          subscriptionId={currentItem?.subscription?.id}
        />
      )}

      {paymentSubscription && (
        <RecordSubscriptiionPayment
          closeModal={() => setPaymentSubscription(!paymentSubscription)}
          facilityType={facilityType}
          facilityId={currentItem?.id}
        />
      )}
    </FacilityTableStyle>
  );
};
