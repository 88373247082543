import React, { useRef, useState, useEffect, Fragment } from 'react';
import '@styles/table.style.css';
import Pagination from '@utils/pagination';
import { serialNum, pageSummaryEnhanced } from '../../../utils/pageSummary';
import { PAGESIZE } from '../../../constants/pageSize';
// import dayjs from 'dayjs';
// import moment from 'moment';
import request, { headers } from '@request';
import ClipLoader from 'react-spinners/ClipLoader';
import { FacilityTableStyle } from '../../facilityTableStyle';
import ActivateFacilitySubscription from '../facilitySettingModal/activateSubscription';

const pageSize = PAGESIZE;
const PatientTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const totalCount = data?.length;

  const [activateSubscription, setActivateSubscription] = useState(false);

  let startSeriaNum = serialNum(currentPage, pageSize);
  const [idOfTable, setIdOfTable] = useState(-1);
  // const history = useHistory();
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIdOfTable(-1);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });
  const [currentItem, setCurrentItem] = useState(null);
  const showModal = (item) => {
    setIdOfTable(item.id);
    setCurrentItem(item);
    // setCurrentName(`${first_name} ${last_name}`);
  };
  const token = localStorage.getItem('token');

  const fetchAllSubscriptions = async () => {
    setLoading(true);
    try {
      const res = await request.get(`/utils/subscriptions/`, headers(token));
      // console.log('response', res.data.results);
      setData(res.data.results);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAllSubscriptions();
  }, []);
  return (
    <FacilityTableStyle>
      <div className="">
        <p className="mt-3">
          {pageSummaryEnhanced(startSeriaNum, totalCount, data)}
        </p>
        {loading === true ? (
          <div className="spin-parent">
            <ClipLoader color="blue" size="40px" className="spinner" />
          </div>
        ) : (
          <table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>
                  <input type="checkbox" name="selectall" />
                </th>
                <th>Account no.</th>
                <th>Name</th>
                <th>Facility Type</th>
                <th>Subscription Category</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Active</th>
                <th>Payment Mode</th>
                <th>Payment Reference</th>
                <th>Auto-renew</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data?.map(
                  (
                    {
                      active,
                      auto_renew,
                      end_date,
                      // facilityId,
                      facilityName,
                      facility_type,
                      id,
                      accountId,
                      payment_mode,
                      payment_reference,
                      planName,
                      start_date,
                    },
                    index
                  ) => (
                    <tr key={index}>
                      <td>{startSeriaNum++}</td>
                      <td>
                        <input type="checkbox" name={id} />
                      </td>
                      <td>{accountId}</td>
                      <td>
                        {/* <Link className="patient-name" to=""> */}
                        {facilityName}
                        {/* </Link> */}
                      </td>
                      <td>{facility_type}</td>
                      <td>{planName || ''}</td>
                      <td>{start_date}</td>
                      <td>{end_date}</td>
                      <td>{active ? 'Active' : 'Inactive'}</td>
                      <td>{payment_mode}</td>
                      <td>{payment_reference}</td>
                      <td>{auto_renew ? 'Yes' : 'No'}</td>
                      <td
                        id={index}
                        className="three-dots act"
                        style={{ width: '9%', position: 'relative' }}
                        onClick={() =>
                          showModal({
                            active,
                            auto_renew,
                            end_date,
                            // facilityId,
                            facilityName,
                            facility_type,
                            id,
                            accountId,
                            payment_mode,
                            payment_reference,
                            planName,
                            start_date,
                          })
                        }
                      >
                        ...
                        {id === idOfTable ? (
                          <Fragment>
                            <span ref={ref} className="popup">
                              <p>Edit subscription expiry date</p>
                              <p
                                onClick={() =>
                                  setActivateSubscription(!activateSubscription)
                                }
                                className="red"
                              >
                                Deactivate subscription
                              </p>
                            </span>
                          </Fragment>
                        ) : null}
                      </td>
                    </tr>
                  )
                )
              ) : (
                <tr>
                  <td>
                    <h4 className="title">No Subscription found</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
        <div className="pagination-container">
          {data && data.length > 0 && (
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={pageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          )}
        </div>
      </div>
      {activateSubscription && (
        <ActivateFacilitySubscription
          subscription={currentItem}
          currentAccount={currentItem?.name}
          onClick={() => setActivateSubscription(!activateSubscription)}
          currentState={currentItem?.active}
          subscriptionId={currentItem?.id}
        />
      )}
    </FacilityTableStyle>
  );
};
export default PatientTable;
