import React, { useState, useEffect, useRef } from 'react';
import CheckBox from '@components/formInputs/checkbox';
import Input from '@components/formInputs/FormInputComponent';
import styled from 'styled-components';
// import request, { headers } from '@request';
// import { toast } from 'react-toastify';
// import Button from '@components/Button/Button';
import CreateNew from './CreateNew';
import { useHandleDialogueDelete } from '@hooks/useDialog';
import { toast } from 'react-toastify';
import request, { headers } from '@request';

export default function PackageServicesTableData({
  // tableData,
  activePlan,
  setActivePlan,
  refetch,
  deleteUrl,
  editUrl,
  activeStatusUpdateUrl,
}) {
  const onchangeCheckbox = async (index) => {
    const plan = [...activePlan];
    plan[index].available = !plan[index].available;
    setActivePlan(plan);
    const payload = plan[index];
    // console.log('payload', payload);
    try {
      await request.patch(
        `${activeStatusUpdateUrl}/${payload.id}/`,
        payload,
        headers(localStorage.getItem('token'))
      );
      refetch();
      toast.success('Service updated');
    } catch (error) {
      console.log('error', error.response);
      toast.error('Error');
    }
  };
  const [createService, setCreateService] = useState(false);

  const ref = useRef(null);

  const [idOfTable, setIdOfTable] = useState(-1);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIdOfTable(-1);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const [currData, setCurrData] = useState(null);

  const showModal = (item) => {
    setCurrData(item);
    setIdOfTable(item.id);
  };

  const deleteTest = async () => {
    try {
      await request.delete(
        `utils/plan/${deleteUrl}/retrieve-update-destroy/${currData.id}`,
        headers(localStorage.getItem('token'))
      );
      refetch();
      toast.success('Service deleted');
    } catch (error) {
      console.log('error', error.response);
      toast.error('Error');
    }
  };

  return (
    <StyleWrapper>
      <table>
        <tr>
          <th>Service</th>
          <th>Unique identifier</th>
          <th>Value</th>
          <th>Description</th>
          <th>Action</th>
        </tr>
        {activePlan?.map((item, index) => (
          <tr key={item?.id}>
            <td>
              <CheckBox
                label={item?.label}
                labelStyle={{ color: item?.device === 'MOBILE' && 'red' }}
                isChecked={item?.available}
                onChangeFunction={() => onchangeCheckbox(index)}
              />
            </td>
            <td>{item?.identifier}</td>
            <td>
              <Input value={item?.value} height="26px" borderColor="#2254D3" />
            </td>

            <td className="foot-note-cell">
              {item?.description && item?.description.length > 50 ? (
                <>
                  <span className="short-text">
                    {item?.description?.slice(0, 50)}...
                  </span>
                  <span className="full-text">{item?.description}</span>
                </>
              ) : (
                item?.description
              )}
            </td>
            <td
              id={item?.id}
              className="three-dots"
              onClick={() => showModal(item)}
            >
              ...
              {item?.id === idOfTable ? (
                <div ref={ref} className="popup">
                  <p onClick={() => setCreateService(!createService)}>Edit</p>
                  <p
                    onClick={() =>
                      useHandleDialogueDelete(
                        deleteTest,
                        `Are you sure you want delete ${currData.label}, Process cannot be reversed?`
                      )
                    }
                    className="text-danger"
                  >
                    Delete service
                  </p>
                </div>
              ) : null}
            </td>
          </tr>
        ))}
      </table>

      {createService && (
        <CreateNew
          createService={createService}
          setCreateService={setCreateService}
          currentData={currData}
          editUrl={editUrl}
        />
      )}
    </StyleWrapper>
  );
}

const StyleWrapper = styled.div``;
