import React, { useState } from 'react';
import Layout from '../layout';
import Header from '../../common/Header';
import { useSelector } from 'react-redux';
import TabNavigation from '@components/TabNavigation';
import { AddonTable } from './AddonTable';

const index = () => {
  const { name } = useSelector((state) => state.country);

  const [activeTab, setActiveTab] = useState('HOSPITAL');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const tabs = [
    {
      id: 'HOSPITAL',
      label: 'Hospital',
      content: <AddonTable activeTab={activeTab} />,
    },
    {
      id: 'DIAGNOSTIC',
      label: 'Diagnostic',
      content: <AddonTable activeTab={activeTab} />,
    },
    {
      id: 'CONSULTANT',
      label: 'Consultant',
      content: <AddonTable activeTab={activeTab} />,
    },
    {
      id: 'PHARMACY',
      label: 'Pharmacy',
      content: <AddonTable activeTab={activeTab} />,
    },
  ];

  return (
    <Layout>
      <Header heading={`${name}: Addons`} />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="thcmgt__tabs">
              <TabNavigation
                tabs={tabs}
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default index;
