import Package from '../Package';

export default function Index() {
  return (
    <Package
      url="plan/pharmacy-plans"
      createUrl="utils/create-health-plan-service-for-a-facility-type"
      editUrl="utils/plan/pharmacy-plan/retrieve-update-destroy"
      activeStatusUpdateUrl="utils/plan/pharmacy-plan/retrieve-update-destroy"
      deleteUrl="pharmacy-plan"
      facilityType="PHARMACY"
    />
  );
}
