import React, { useRef, useState, useEffect, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import '@styles/table.style.css';
import ClipLoader from 'react-spinners/ClipLoader';
import { FacilityTableStyle } from '../../facilityTableStyle';
import { useFetchAddon } from '../../../hooks/queries';
import CreateAddon from './createAddons';
import Button from '../../../components/Button/Button';
import request, { headers } from '@request';
import { useHandleDialogueDelete } from '@hooks/useDialog';

export const AddonTable = ({ activeTab }) => {
  const [currentItem, setCurrentItem] = useState('');
  const { data, isLoading, refetch } = useFetchAddon(activeTab);
  const [createAddon, setCreateAddon] = useState(false);
  const [updateAddon, setUpdateAddon] = useState(false);
  console.log('currentItem', currentItem);

  const [idOfTable, setIdOfTable] = useState(-1);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIdOfTable(-1);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const showModal = (item) => {
    setIdOfTable(item?.id);
    setCurrentItem(item);
  };

  const deleteAddon = async () => {
    try {
      const res = await request.delete(
        `utils/addons/${currentItem?.id}/`,
        headers(localStorage.getItem('token'))
      );
      console.log('response', res.status);
      refetch();
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <FacilityTableStyle>
      <Button width="150px" onClick={() => setCreateAddon(true)}>
        Create Addon
      </Button>

      {isLoading ? (
        <div className="spin-parent">
          <ClipLoader color="blue" size="40px" className="spinner" />
        </div>
      ) : (
        <div className="">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Label</th>
                <th>Description</th>
                <th>Yearly Amount</th>
                <th>Monthly Amount</th>

                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data?.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.name}</td>
                    <td>{item?.label}</td>
                    <td>{item?.description}</td>
                    <td>{item?.amount_yearly}</td>
                    <td>{item?.amount_monthly}</td>

                    <td
                      id={index}
                      className="three-dots"
                      onClick={() => showModal(item)}
                    >
                      ...
                      {item?.id === idOfTable ? (
                        <Fragment>
                          <span ref={ref} className="popup">
                            <p onClick={() => setUpdateAddon(!updateAddon)}>
                              Edit addon
                            </p>
                            <p
                              onClick={() =>
                                useHandleDialogueDelete(
                                  deleteAddon,
                                  `Are you sure you want delete '${currentItem.label}' for ${currentItem.facility_type}?, Process cannot be reversed`
                                )
                              }
                              className="red"
                            >
                              Delete addon
                            </p>
                          </span>
                        </Fragment>
                      ) : null}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <h4 className="title">No addons</h4>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      {createAddon && (
        <CreateAddon
          createAddon={createAddon}
          setCreateAddon={setCreateAddon}
          refetch={refetch}
        />
      )}
      {updateAddon && (
        <CreateAddon
          createAddon={updateAddon}
          setCreateAddon={setUpdateAddon}
          refetch={refetch}
          currentData={currentItem}
        />
      )}
    </FacilityTableStyle>
  );
};
