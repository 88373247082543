import React, { useState, useEffect } from 'react';
import ModalWrapper from '@components/modalComponents/NewModal';
import FormTitleSection from '@components/modalComponents/FormTitleSection';
import request, { multipartHeader } from '@request';
import { toast } from 'react-toastify';
import Button from '@components/Button/Button';
import SubmitSuccess from '@components/SubmitSuccess';
import Select from 'react-select';
import { whiteBackground } from '@styles/react-select-custom';
import FormInputComponent from '@components/formInputs/FormInputComponent';
import {
  drugCategoryOptions,
  drugModeOptions,
  drugUnitOptions,
  drugTypeOptions,
} from '@utils/listingOptions/DrugOptions';
import { useFormik } from 'formik';
import { useDrugGenericName } from '@hooks/queries';
import styled from 'styled-components';
import SelectWrapper from '@components/formInputs/selectWrapper';
import TextAreaInput from '@components/formInputs/TextAreaInput';
import FormImgComponent from '@components/formInputs/FormImgComponent';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  sku: Yup.string().required('Required'),
  drug_name: Yup.string(),
  category: Yup.string(),
  mode: Yup.string(),
  unit: Yup.string(),
  strength: Yup.string(),
  formulation: Yup.string(),
  generic_name: Yup.string().required('Required'),
  item_pack_mode: Yup.string(),
  reorder_quantity: Yup.number(),
  drug_type: Yup.string(),
  description: Yup.string(),
});

export default function CreateDataset({
  onClick,
  currData,
  createDatasetEndpoint,
  editDatasetEndpoint,
  refetch,
}) {
  const [data] = useState(currData);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const drugGenericNameOptions = useDrugGenericName();

  const [profileImg, setProfileImg] = useState({});

  const [profileImgError] = useState('');
  const imgType = ['jpg', 'jpeg', 'png', 'PNG'];
  const itemModeOptions = [
    { label: 'Pack', value: 'Pack' },
    { label: 'Card', value: 'Card' },
    { label: 'Unit', value: 'Unit' },
    { label: 'Strip', value: 'Strip' },
    { label: 'Blister', value: 'Blister' },
    { label: 'Pcs', value: 'Pcs' },
  ];
  const onSubmit = async (value) => {
    const formData = new FormData();
    for (const key in value) {
      formData.append(key, value[key]);
    }
    if (profileImg.name) {
      formData.append('drug_img', profileImg);
    }

    setLoading(true);
    const requestMethod = data?.id ? 'patch' : 'post';
    setLoading(true);

    try {
      const res = await request[requestMethod](
        data?.id ? editDatasetEndpoint : createDatasetEndpoint,
        formData,
        multipartHeader
      );

      if (res.status === 200 || res.status === 201) {
        // Assuming successful upload status code
        refetch();
        setSubmitted(true);
        toast.success('dataset created successully');
      } else {
        throw new Error('could not create dataset');
      }
    } catch (err) {
      console.error('Error :', err.response);
      toast.error('Error creating dataset. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const initialValues = {
    sku: '',
    drug_name: '',
    category: '',
    mode: '',
    unit: '',
    strength: '',
    generic_name: '',
    formulation: '',
    item_pack_mode: '',
    reorder_quantity: 0,
    drug_type: '',
    description: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const onLoad = () => {
    if (data) {
      const fields = Object.keys(initialValues);
      fields.forEach((field) => {
        formik.setFieldValue(field, data[field], false);
        // }
      });
    }
  };

  useEffect(() => {
    if (data) {
      onLoad();
    }
  }, [data]);

  return (
    <ModalWrapper width="50%">
      <FormTitleSection
        onClick={onClick}
        title={
          data?.id
            ? `Edit dataset-${data?.drug_name}-${data?.generic_name}-${data?.strength}${data?.unit}-${data?.mode}`
            : `Create dataset`
        }
      />
      {submitted ? (
        <SubmitSuccess
          message={
            data?.id
              ? `${data?.drug_name}-${data?.generic_name}-${data?.strength}${data?.unit}-${data?.mode} edited`
              : `${formik.values.sku}-${formik?.values?.drug_name} dataset created`
          }
        />
      ) : (
        <>
          <FormWrapper onSubmit={formik.handleSubmit}>
            <div className="img-wrapper">
              <div className="img">
                <FormImgComponent
                  pictureType={imgType}
                  type="file"
                  error={profileImgError}
                  selectedFile={profileImg}
                  setSelectedFile={setProfileImg}
                  acceptTypes={`jpg, jpeg, gif, png. Size Limit 2mb`}
                  imgUrl={profileImg}
                  pictureIcon={''}
                  className="upload-icon"
                />
              </div>
              <div className="textareai">
                <TextAreaInput
                  rows="2"
                  placeholder="Description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={formik.errors.description}
                />
              </div>
            </div>
            <div className="grid-row">
              {/* New added field above */}
              <FormInputComponent
                label="SKU"
                placeholder="Type SKU"
                name="sku"
                value={formik.values.sku}
                onChange={formik.handleChange}
                labelColor="#2254D3"
                required
                error={formik.errors.sku}
              />
              <SelectWrapper
                // required
                label="Item mode"
                error={formik.errors.drug_pack_mode}
              >
                <Select
                  placeholder="Select item mode"
                  styles={whiteBackground}
                  name={'item_pack_mode'}
                  options={itemModeOptions}
                  value={itemModeOptions?.find(
                    (item) => item.value === formik.values.item_pack_mode
                  )}
                  onChange={(e) => {
                    console.log('e', e);
                    formik.setFieldValue('item_pack_mode', e.value, false);
                  }}
                />
              </SelectWrapper>

              <FormInputComponent
                label="Drug Name"
                placeholder="Type drug name"
                name="drug_name"
                value={formik.values.drug_name}
                onChange={formik.handleChange}
                labelColor="#2254D3"
                // required
                error={formik.errors.drug_name}
              />
              <SelectWrapper
                label="Drug Generic name"
                required
                error={formik.errors.generic_name}
              >
                <Select
                  placeholder="Select drug generic name"
                  value={drugGenericNameOptions?.find(
                    (item) => item.value === formik.values.generic_name
                  )}
                  name="generic_name"
                  styles={whiteBackground}
                  onChange={(e) => {
                    formik.setFieldValue('generic_name', e.value, false);
                  }}
                  options={drugGenericNameOptions}
                />
              </SelectWrapper>
              <FormInputComponent
                label="Formulation"
                placeholder="Formulation eg 20mg + 40mg"
                value={formik.values.formulation}
                name="formulation"
                styles={whiteBackground}
                onChange={formik.handleChange}
              />
              <SelectWrapper
                // required
                label="Drug Category"
                error={formik.errors.category}
              >
                <Select
                  placeholder="Select drug Category"
                  name={'category'}
                  styles={whiteBackground}
                  value={drugCategoryOptions?.find(
                    (item) => item.value === formik.values.category
                  )}
                  onChange={(e) => {
                    formik.setFieldValue('category', e.value, false);
                  }}
                  options={drugCategoryOptions}
                />
              </SelectWrapper>

              <SelectWrapper
                // required
                label="Drug Mode"
                error={formik.errors.mode}
              >
                <Select
                  placeholder="Select drug Mode"
                  name="mode"
                  styles={whiteBackground}
                  value={drugModeOptions?.find(
                    (item) => item.value === formik.values.mode
                  )}
                  onChange={(e) => {
                    formik.setFieldValue('mode', e.value, false);
                  }}
                  options={drugModeOptions}
                />
              </SelectWrapper>
              <FormInputComponent
                label="Strength"
                placeholder={'Enter Strength'}
                value={formik.values.strength}
                onChange={formik.handleChange}
                name="strength"
                labelColor="#2254D3"
                borderColor="#2254D3"
                // required
                error={formik.errors.strength}
              />
              <SelectWrapper label="Unit" error={formik.errors.unit}>
                <Select
                  placeholder="Select drug unit"
                  value={drugUnitOptions?.find(
                    (item) => item.value === formik.values.unit
                  )}
                  name="unit"
                  styles={whiteBackground}
                  onChange={(e) => {
                    formik.setFieldValue('unit', e.value, false);
                  }}
                  options={drugUnitOptions}
                />
              </SelectWrapper>
              <FormInputComponent
                label="Reorder quantity"
                placeholder="Type reorder quantity"
                value={formik.values.reorder_quantity}
                name="reorder_quantity"
                onChange={formik.handleChange}
                type={'number'}
                // required
                error={formik.errors.reorder_quantity}
                labelColor="#2254D3"
                borderColor="#2254D3"
              />
              <SelectWrapper
                // required
                label="Drug type"
                placeholder="Drug type"
                error={formik.errors.drug_type}
              >
                <Select
                  placeholder="Drug type"
                  value={drugTypeOptions?.find(
                    (item) => item.value === formik.values.drug_type
                  )}
                  name="drug_type"
                  styles={whiteBackground}
                  onChange={(e) => {
                    formik.setFieldValue('drug_type', e.value, false);
                  }}
                  options={drugTypeOptions}
                />
              </SelectWrapper>
            </div>

            <div className="form--submit">
              <div className="modal-footer">
                <Button type="submit">
                  {loading ? 'Saving...' : 'Add Dataset'}
                </Button>
              </div>
            </div>
          </FormWrapper>
        </>
      )}
    </ModalWrapper>
  );
}

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 5px;
  & .grid-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .img-wrapper {
    width: 100%;
    display: flex;
    gap: 5px;
    .img {
      width: 20%;
    }
    .textareai {
      width: 80%;
      /* height: 100px; */
    }
  }
`;
