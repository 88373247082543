import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { useFetchDrugGenericName } from '@hooks/queries';
import Checkbox from '@components/formInputs/checkbox';
import TableLoader from '@components/TableLoader';
// import { SmallButton } from '@components/Button/SmallButton';
import CreateGenericDrug from './CreateGenericDrug';
import { toast } from 'react-toastify';
import request, { headers } from '@request';
import { useHandleDialogueDelete } from '@hooks/useDialog';
import CustomSearch from '@components/formInputs/CustomSearch';
import Button from '@components/Button/Button';
import Uploadcsv from './Uploadcsv';

export default function GenericName() {
  const { data, isLoading, refetch } = useFetchDrugGenericName();

  const [updateCat, setUpdateCat] = useState(false);
  const [createTest, setCreateTest] = useState(false);
  const [uploadcsv, setUploadcsv] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState('');

  const ref = useRef(null);

  const [idOfTable, setIdOfTable] = useState(-1);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIdOfTable(-1);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const [currData, setCurrData] = useState(null);

  const showModal = (item) => {
    setCurrData(item);
    setIdOfTable(item.id);
  };

  const token = localStorage.getItem('token');
  const deleteTest = async () => {
    try {
      await request.delete(
        `utils/drug-generic-name/${currData?.id}/delete/`,
        headers(token)
      );
      toast.success('Drug deleted');
    } catch (error) {
      console.log('error', error.response);
      toast.error('Error');
    }
  };

  const searchResult = useMemo(() => {
    return data?.filter((item) =>
      item.name.toLowerCase().includes(searchKeyWord.toLowerCase())
    );
  }, [searchKeyWord, data]);

  const clearDataset = async () => {
    try {
      await request.delete(
        `utils/drug-generic-name-truncate/`,
        headers(localStorage.getItem('token'))
      );
      refetch();
    } catch (err) {
      console.error('Error :', err.response);
    }
  };
  return (
    <InvestigationStyle>
      <main className="main-area">
        <div className="d-flex justify-content-between gap-3">
          <CustomSearch
            placeholder="Search..."
            handleChange={(e) => setSearchKeyWord(e.target.value)}
            value={searchKeyWord}
          />
          <Button
            type="button"
            onClick={() =>
              useHandleDialogueDelete(
                clearDataset,
                `Are you sure you want to truncate drug generic name? This means all the facility depending on it for their activity will not have access?, Process cannot be reversed?`
              )
            }
            backgroundColor="red"
            width="200px"
          >
            Clear Table
          </Button>
          <Button type="button" onClick={() => setUploadcsv(!uploadcsv)}>
            Upload csv
          </Button>
          <Button type="button" onClick={() => setCreateTest(true)}>
            Create new drug
          </Button>
        </div>
        {isLoading && <TableLoader rows={5} columns={5} />}
        {data && (
          <table>
            <thead>
              <tr>
                <td>
                  <Checkbox color="#2254D3" />
                </td>
                <th>S/N</th>
                <th>ID</th>
                <th>Name</th>
                <th>Formulation</th>
                <th>Strength</th>
                <th>Unit</th>
                <th>Mode</th>
                <th>Category</th>
                <th>Indication</th>
                <th>Section</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {searchResult && searchResult.length > 0 ? (
                searchResult.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Checkbox name={item.id} color="#2254D3" />
                    </td>
                    <td className="w-70">{index + 1}</td>
                    <td className="w-70">{item?.id?.slice(0, 8)}</td>
                    <td>{item?.name}</td>
                    <td>{item?.formulary}</td>
                    <td>{item?.strength}</td>
                    <td>{item?.unit}</td>
                    <td>{item?.mode}</td>
                    <td>{item?.category}</td>
                    <td>{item?.indication}</td>
                    <td>{item?.section}</td>
                    {/* formulary
category
mode
unit
strength
description
section
indication */}
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      id={item?.id}
                      className="three-dots"
                      onClick={() => showModal(item)}
                    >
                      ...
                      {item?.id === idOfTable ? (
                        <div ref={ref} className="popup">
                          <p onClick={() => setUpdateCat(!updateCat)}>Edit</p>
                          <p
                            onClick={() =>
                              useHandleDialogueDelete(
                                deleteTest,
                                `Are you sure you want delete ${currData.name}, Process cannot be reversed?`
                              )
                            }
                            className="text-danger"
                          >
                            Delete
                          </p>
                        </div>
                      ) : null}
                    </td>
                  </tr>
                ))
              ) : (
                <p>No generic name </p>
              )}
            </tbody>
          </table>
        )}
      </main>
      {updateCat && (
        <CreateGenericDrug
          closeModal={() => setUpdateCat(false)}
          currentData={currData}
          editEndpoint={`utils/drug-generic-name/${currData?.id}/`}
          formType="drug"
        />
      )}

      {createTest && (
        <CreateGenericDrug
          createEndpoint="utils/drug-generic-name/create/"
          closeModal={() => setCreateTest(false)}
          formType="drug"
        />
      )}

      {uploadcsv && (
        <Uploadcsv
          refetch={refetch}
          isModalOpen={uploadcsv}
          setIsModalOpen={setUploadcsv}
          endpoint="utils/utils/import-drug-generic-name/"
        />
      )}
    </InvestigationStyle>
  );
}

const InvestigationStyle = styled.div``;
