export const drugCategoryOptions = [
  { value: '', label: 'Select Drug Category' },
  { value: 'Acne', label: 'Acne' },
  { value: 'Acute Diabetic', label: 'Acute Diabetic' },
  { value: 'Allergy Medications', label: 'Allergy Medications' },
  { value: 'Alternative Medicines', label: 'Alternative Medicines' },
  { value: 'Alzheimer', label: 'Alzheimer' },
  { value: 'Analgesics', label: 'Analgesics' },
  { value: 'Anti Diabetics', label: 'Anti Diabetics' },
  { value: 'Anti Infectives', label: 'Anti Infectives' },
  { value: 'Anti Inflammatory', label: 'Anti Inflammatory' },
  { value: 'Antiretroviral', label: 'Antiretroviral' },
  { value: 'Antibiotics', label: 'Antibiotics' },
  { value: 'Anticoagulants', label: 'Anticoagulants' },
  { value: 'Antidepressants', label: 'Antidepressants' },
  { value: 'Antimalarial', label: 'Antimalarial' },
  { value: 'Antiplatelets', label: 'Antiplatelets' },
  { value: 'Antipsychotics', label: 'Antipsychotics' },
  { value: 'Antiviral', label: 'Antiviral' },
  { value: 'Asthma Medications', label: 'Asthma Medications' },
  { value: 'Baby & Child Health', label: 'Baby & Child Health' },
  { value: 'Biologicals', label: 'Biologicals' },
  { value: 'Blockers', label: 'Blockers' },
  { value: 'Blood Thinners', label: 'Blood Thinners' },
  { value: 'Cancer', label: 'Cancer' },
  { value: 'Cardiovascular ', label: 'Cardiovascular ' },
  { value: 'Central Nervous System', label: 'Central Nervous System' },
  { value: 'Cold & Cough', label: 'Cold & Cough' },
  { value: 'Cytostatics', label: 'Cytostatics' },
  { value: 'Dental & Oral Care', label: 'Dental & Oral Care' },
  { value: 'Dermatological ', label: 'Dermatological ' },
  { value: 'Diabetic', label: 'Diabetic' },
  { value: 'Diet & Detox', label: 'Diet & Detox' },
  { value: 'Digestive System', label: 'Digestive System' },
  { value: 'Diuretics', label: 'Diuretics' },
  { value: 'Ear care', label: 'Ear care' },
  { value: 'Endocrinology', label: 'Endocrinology' },
  { value: 'Epilepsy', label: 'Epilepsy' },
  { value: 'Eye care', label: 'Eye care' },
  { value: 'First Aid', label: 'First Aid' },
  { value: 'Foot Care', label: 'Foot Care' },
  { value: 'Gastrointestinal ', label: 'Gastrointestinal ' },
  { value: 'Genitourinary ', label: 'Genitourinary ' },
  { value: 'Hair Care', label: 'Hair Care' },
  { value: 'Home Medical Equipment', label: 'Home Medical Equipment' },
  { value: 'Hormonal ', label: 'Hormonal ' },
  { value: 'Immune Suppresants', label: 'Immune Suppresants' },
  { value: 'Mobility Aids', label: 'Mobility Aids' },
  { value: 'Inhibitors', label: 'Inhibitors' },
  { value: 'Investigational drugs', label: 'Investigational drugs' },
  { value: 'Laxatives', label: 'Laxatives' },
  { value: 'Medical Supports', label: 'Medical Supports' },
  { value: 'Musculo Skeletal ', label: 'Musculo Skeletal ' },
  { value: 'Neoplastic Medicines', label: 'Neoplastic Medicines' },
  { value: 'Nervous System ', label: 'Nervous System ' },
  { value: 'Neurologic', label: 'Neurologic' },
  { value: 'Nutrition & Blood', label: 'Nutrition & Blood' },
  { value: 'Obstetrics and Gynecology', label: 'Obstetrics and Gynecology' },
  { value: 'Ophthalmic ', label: 'Ophthalmic ' },
  { value: 'Osteoporosis', label: 'Osteoporosis' },
  { value: 'Other', label: 'Other' },
  { value: 'Pain Relievers', label: 'Pain Relievers' },
  {
    value: 'Pregnancy and Contraception',
    label: 'Pregnancy and Contraception',
  },
  { value: 'Psychaitric', label: 'Psychaitric' },
  { value: 'Psychotherapeutic ', label: 'Psychotherapeutic ' },
  { value: 'Radiologic ', label: 'Radiologic ' },
  { value: 'Renal ', label: 'Renal ' },
  { value: 'Reproductive', label: 'Reproductive' },
  { value: 'Respiratory ', label: 'Respiratory ' },
  { value: 'Rheumatologic', label: 'Rheumatologic' },
  { value: 'Sexual Health', label: 'Sexual Health' },
  { value: 'Skin Care', label: 'Skin Care' },
  { value: 'Smoking Cessation', label: 'Smoking Cessation' },
  { value: 'Special Drugs', label: 'Special Drugs' },
  { value: 'Sports & Fitness', label: 'Sports & Fitness' },
  { value: 'Statins', label: 'Statins' },
  { value: 'Steroids', label: 'Steroids' },
  { value: 'Supplements', label: 'Supplements' },
  { value: 'Ulcer & Digestion', label: 'Ulcer & Digestion' },
  { value: 'Urinary Tract ', label: 'Urinary Tract ' },
  { value: 'Urologic', label: 'Urologic' },
  { value: 'Vitamins & Multivitamins', label: 'Vitamins & Multivitamins' },
  { value: 'Other', label: 'Other' },
];

export const drugModeOptions = [
  { value: '', label: 'Select Drug Mode' },
  { value: 'Aerosol', label: 'Aerosol' },
  { value: 'Ampoule', label: 'Ampoule' },
  { value: 'Bottle', label: 'Bottle' },
  { value: 'Capsule', label: 'Capsule' },
  { value: 'Cream', label: 'Cream' },
  { value: 'Dispersible Tablet', label: 'Dispersible Tablet' },
  { value: 'Drops', label: 'Drops' },
  { value: 'Dry Powder', label: 'Dry Powder' },
  { value: 'Gel', label: 'Gel' },
  { value: 'Granules', label: 'Granules' },
  { value: 'Implant', label: 'Implant' },
  { value: 'Injection', label: 'Injection' },
  { value: 'Liquid', label: 'Liquid' },
  { value: 'Lotion', label: 'Lotion' },
  { value: 'Ointment', label: 'Ointment' },

  { value: 'Pessaries', label: 'Pessaries' },
  { value: 'Powder', label: 'Powder' },
  { value: 'Respiratory', label: 'Respiratory' },
  { value: ' Retention Enema', label: 'Retention Enema' },
  { value: 'Ring', label: 'Ring' },
  { value: 'Solid', label: 'Solid' },
  { value: 'Solution', label: 'Solution' },
  { value: 'Spray', label: 'Spray' },
  { value: 'Suppository', label: 'Suppository' },
  { value: 'Suspension', label: 'Suspension' },
  { value: 'Syrup', label: 'Syrup' },
  { value: 'Tablet', label: 'Tablet' },
  { value: 'Topical', label: 'Topical' },
  { value: 'Transdermal patch', label: 'Transdermal patch' },
  { value: 'Vaginal Ring', label: 'Vaginal Ring' },
  { value: 'Vial', label: 'Vial' },
  { value: 'Other', label: 'Other' },
];

export const drugRouteOptions = [
  { value: 'Select route', label: '' },
  { label: 'Dental', value: 'Dental' },
  { label: 'Inhalation', value: 'Inhalation' },
  { label: 'Injection', value: 'Injection' },
  { label: 'Intradermal', value: 'Intradermal' },
  { label: 'Intralessional', value: 'Intralessional' },
  { label: 'Intramuscular', value: 'Intramuscular' },
  { label: 'Intraosseous', value: 'Intraosseous' },
  { label: 'Intraperitoneal', value: 'Intraperitoneal' },
  { label: 'Intrarticular', value: 'Intrarticular' },
  { label: 'Intrathecal', value: 'Intrathecal' },
  { label: 'Intrauterine', value: 'Intrauterine' },
  { label: 'Intravenous', value: 'Intravenous' },
  { label: 'Local', value: 'Local' },
  { label: 'Locoregional', value: 'Locoregional' },
  { label: 'Nasal', value: 'Nasal' },
  { label: 'Ophthalmic', value: 'Ophthalmic' },
  { label: 'Oral', value: 'Oral' },
  { label: 'Otological', value: 'Otological' },
  { label: 'Rectal', value: 'Rectal' },
  { label: 'Subcutaneous', value: 'Subcutaneous' },
  { label: 'Subdermal', value: 'Subdermal' },
  { label: 'Sublingual', value: 'Sublingual' },
  { label: 'Topical', value: 'Topical' },
  { label: 'Vaginal', value: 'Vaginal' },
];

export const drugFrequencyOptions = [
  { value: '', label: 'Select Frequency' },
  { value: '12H - Every 12 HOURS', label: '12H - Every 12 HOURS' },
  { value: '14D - Every 14 DAYS', label: '14D - Every 14 DAYS' },
  { value: '1H - Every HOUR', label: '1H - Every HOUR' },
  { value: '1X07 - ONCE Daily at 7am', label: '1X07 - ONCE Daily at 7am' },
  {
    value: '1X12 - ONCE Daily at Midday',
    label: '1X12 - ONCE Daily at Midday',
  },
  { value: '1X16 - ONCE Daily at 4pm', label: '1X16 - ONCE Daily at 4pm' },
  { value: '1X20 - ONCE Daily at 8pm', label: '1X20 - ONCE Daily at 8pm' },
  { value: '1X22 - ONCE Daily at 10pm', label: '1X22 - ONCE Daily at 10pm' },
  { value: '21D - Every 21 DAYS', label: '21D - Every 21 DAYS' },
  { value: '24H - Every 24 HOURS', label: '24H - Every 24 HOURS' },
  { value: '28D - Every 28 DAYS', label: '28D - Every 28 DAYS' },
  { value: '2H - Every 2 HOURS', label: '2H - Every 2 HOURS' },
  { value: '4H - Every 4 HOURS', label: '4H - Every 4 HOURS' },
  { value: '2MONTH - Every 2 MONTHS', label: '2MONTH - Every 2 MONTHS' },
  {
    value: '2W FRI/MON - TWICE Weekly Friday & Monday',
    label: '2W FRI/MON - TWICE Weekly Friday & Monday',
  },
  {
    value: '2W MON/THU - TWICE Weekly Monday & Thursday',
    label: '2W MON/THU - TWICE Weekly Monday & Thursday',
  },
  {
    value: '2W SAT/SUN - TWICE Weekly Saturday & Sunday at 12 Midday',
    label: '2W SAT/SUN - TWICE Weekly Saturday & Sunday at 12 Midday',
  },
  {
    value: '2W SAT/TUE - TWICE Weekly Saturday & Tuesday',
    label: '2W SAT/TUE - TWICE Weekly Saturday & Tuesday',
  },
  {
    value: '2W SUN/WED - TWICE Weekly Sunday & Wednesday',
    label: '2W SUN/WED - TWICE Weekly Sunday & Wednesday',
  },
  {
    value: '2W THU/SUN - TWICE Weekly Thursday & Sunday',
    label: '2W THU/SUN - TWICE Weekly Thursday & Sunday',
  },
  {
    value: '2W TUE/FRI - TWICE Weekly Tuesday & Friday',
    label: '2W TUE/FRI - TWICE Weekly Tuesday & Friday',
  },
  {
    value: '2W WED/SAT - TWICE Weekly Wednesday & Saturday',
    label: '2W WED/SAT - TWICE Weekly Wednesday & Saturday',
  },
  {
    value: '2WBD F/SAT - TWICE A DAY - TWICE Weekly Friday & Saturday',
    label: '2WBD F/SAT - TWICE A DAY - TWICE Weekly Friday & Saturday',
  },
  {
    value: '2X7/22 - TWICE Daily at 7am and 10pm',
    label: '2X7/22 - TWICE Daily at 7am and 10pm',
  },
  {
    value: '2X8/20 - TWICE Daily at 8am and 8pm',
    label: '2X8/20 - TWICE Daily at 8am and 8pm',
  },
  {
    value: '2X10/22 - TWICE Daily at 10am and 10pm',
    label: '2X10/22 - TWICE Daily at 10am and 10pm',
  },
  {
    value: '2X12/16 - TWICE Daily at Midday and 4pm',
    label: '2X12/16 - TWICE Daily at Midday and 4pm',
  },
  {
    value: '2X12/22 - TWICE Daily at Midday and 10pm',
    label: '2X12/22 - TWICE Daily at Midday and 10pm',
  },
  {
    value: '2X16/22 - TWICE Daily at 4pm and 10pm',
    label: '2X16/22 - TWICE Daily at 4pm and 10pm',
  },
  {
    value: '2X6/18 - TWICE Daily at 6am and 6pm',
    label: '2X6/18 - TWICE Daily at 6am and 6pm',
  },
  { value: '5H - Every FIVE hours', label: '5H - Every FIVE hours' },
  {
    value: '5WPM - Monday to Friday at 10pm',
    label: '5WPM - Monday to Friday at 10pm',
  },
  { value: '5X - FIVE times daily', label: '5X - FIVE times daily' },
  { value: '6H - Every SIX hours', label: '6H - Every SIX hours' },
  {
    value: '6X - SIX times day 4am, 8am, Noon, 4pm, 8pm, Midnight',
    label: '6X - SIX times day 4am, 8am, Noon, 4pm, 8pm, Midnight',
  },
  {
    value: '72H 10PM - Every 3rd DAY at 10pm',
    label: '72H 10PM - Every 3rd DAY at 10pm',
  },
  {
    value: '72H 10AM - Every 3rd DAY at 10am',
    label: '72H 10AM - Every 3rd DAY at 10am',
  },
  { value: '7D - Every 7 DAYS', label: '7D - Every 7 DAYS' },
  { value: '8H - Every EIGHT hours', label: '8H - Every EIGHT hours' },
  {
    value: 'AD 10PM - Alternate days at 10pm',
    label: 'AD 10PM - Alternate days at 10pm',
  },
  {
    value: 'AD 10AM - Alternate days at 10am',
    label: 'AD 10AM - Alternate days at 10am',
  },
  {
    value: 'AXFRI - EVERY DAY AT 7am EXCEPT Friday',
    label: 'AXFRI - EVERY DAY AT 7am EXCEPT Friday',
  },
  {
    value: 'AXMON - EVERY DAY AT 7am EXCEPT Monday',
    label: 'AXMON - EVERY DAY AT 7am EXCEPT Monday',
  },
  {
    value: 'AXTUE - EVERY DAY AT 7am EXCEPT Tuesday',
    label: 'AXTUE - EVERY DAY AT 7am EXCEPT Tuesday',
  },
  {
    value: 'AXWED - EVERY DAY AT 7am EXCEPT Wednesday',
    label: 'AXWED - EVERY DAY AT 7am EXCEPT Wednesday',
  },
  {
    value: 'AXTHU - EVERY DAY AT 7am EXCEPT Thursday',
    label: 'AXTHU - EVERY DAY AT 7am EXCEPT Thursday',
  },
  {
    value: 'AXSAT - EVERY DAY AT 7am EXCEPT Saturday',
    label: 'AXSAT - EVERY DAY AT 7am EXCEPT Saturday',
  },
  {
    value: 'AXSUN - EVERY DAY AT 7am EXCEPT Sunday',
    label: 'AXSUN - EVERY DAY AT 7am EXCEPT Sunday',
  },
  { value: 'CONTINOUS - Over 24 hours', label: 'CONTINOUS - Over 24 hours' },
  {
    value: 'FRMOON - TWICE Weeekly Friday Monday at NIGHT',
    label: 'FRMOON - TWICE Weeekly Friday Monday at NIGHT',
  },
  {
    value: 'G21/S7 - ONCE daily for 21 days then miss 7 days',
    label: 'G21/S7 - ONCE daily for 21 days then miss 7 days',
  },
  { value: 'MONTH - Every Month', label: 'MONTH - Every Month' },
  {
    value: 'WF - ONCE weekly - Friday at 7am',
    label: 'WF - ONCE weekly - Friday at 7am',
  },
  {
    value: 'WM - ONCE weekly - Monday at 7am',
    label: 'WM - ONCE weekly - Monday at 7am',
  },
  {
    value: 'WT - ONCE weekly - Tuesday at 7am',
    label: 'WT - ONCE weekly - Tuesday at 7am',
  },
  {
    value: 'WW - ONCE weekly - Wednesday at 7am',
    label: 'WW - ONCE weekly - Wednesday at 7am',
  },
  {
    value: 'WTH - ONCE weekly - Thursday at 7am',
    label: 'WTH - ONCE weekly - Thursday at 7am',
  },
  {
    value: 'WS - ONCE weekly - Saturday at 7am',
    label: 'WS - ONCE weekly - Saturday at 7am',
  },
  {
    value: 'WSU- ONCE weekly - Sunday at 7am',
    label: 'WSU- ONCE weekly - Sunday at 7am',
  },
  {
    value: 'WWPM - Every Wednesday at 10pm',
    label: 'WWPM - Every Wednesday at 10pm',
  },
  {
    value: '72H 10PM - Every 3rd DAY at 10pm',
    label: '72H 10PM - Every 3rd DAY at 10pm',
  },
  {
    value: '72H 10AM - Every 3rd DAY at 10am',
    label: '72H 10AM - Every 3rd DAY at 10am',
  },
  { value: '7D - Every 7 DAYS', label: '7D - Every 7 DAYS' },
  { value: '8H - Every EIGHT hours', label: '8H - Every EIGHT hours' },
  {
    value: 'AD 10PM - Alternate days at 10pm',
    label: 'AD 10PM - Alternate days at 10pm',
  },
  {
    value: 'AD 10AM - Alternate days at 10am',
    label: 'AD 10AM - Alternate days at 10am',
  },
  {
    value: 'AXFRI - EVERY DAY AT 7am EXCEPT Friday',
    label: 'AXFRI - EVERY DAY AT 7am EXCEPT Friday',
  },
  {
    value: 'AXMON - EVERY DAY AT 7am EXCEPT Monday',
    label: 'AXMON - EVERY DAY AT 7am EXCEPT Monday',
  },
  {
    value: 'AXTUE - EVERY DAY AT 7am EXCEPT Tuesday',
    label: 'AXTUE - EVERY DAY AT 7am EXCEPT Tuesday',
  },
  {
    value: 'AXWED - EVERY DAY AT 7am EXCEPT Wednesday',
    label: 'AXWED - EVERY DAY AT 7am EXCEPT Wednesday',
  },
  {
    value: 'AXTHU - EVERY DAY AT 7am EXCEPT Thursday',
    label: 'AXTHU - EVERY DAY AT 7am EXCEPT Thursday',
  },
  {
    value: 'AXSAT - EVERY DAY AT 7am EXCEPT Saturday',
    label: 'AXSAT - EVERY DAY AT 7am EXCEPT Saturday',
  },
  {
    value: 'AXSUN - EVERY DAY AT 7am EXCEPT Sunday',
    label: 'AXSUN - EVERY DAY AT 7am EXCEPT Sunday',
  },
  { value: 'CONTINOUS - Over 24 hours', label: 'CONTINOUS - Over 24 hours' },
  {
    value: 'FRMOON - TWICE Weeekly Friday Monday at NIGHT',
    label: 'FRMOON - TWICE Weeekly Friday Monday at NIGHT',
  },
  {
    value: 'G21/S7 - ONCE daily for 21 days then miss 7 days',
    label: 'G21/S7 - ONCE daily for 21 days then miss 7 days',
  },
  { value: 'MONTH - Every Month', label: 'MONTH - Every Month' },
  {
    value: 'WF - ONCE weekly - Friday at 7am',
    label: 'WF - ONCE weekly - Friday at 7am',
  },
  {
    value: 'WM - ONCE weekly - Monday at 7am',
    label: 'WM - ONCE weekly - Monday at 7am',
  },
  {
    value: 'WT - ONCE weekly - Tuesday at 7am',
    label: 'WT - ONCE weekly - Tuesday at 7am',
  },
  {
    value: 'WW - ONCE weekly - Wednesday at 7am',
    label: 'WW - ONCE weekly - Wednesday at 7am',
  },
  {
    value: 'WTH - ONCE weekly - Thursday at 7am',
    label: 'WTH - ONCE weekly - Thursday at 7am',
  },
  {
    value: 'WS - ONCE weekly - Saturday at 7am',
    label: 'WS - ONCE weekly - Saturday at 7am',
  },
  {
    value: 'WSU- ONCE weekly - Sunday at 7am',
    label: 'WSU- ONCE weekly - Sunday at 7am',
  },
  {
    value: 'WWPM - Every Wednesday at 10pm',
    label: 'WWPM - Every Wednesday at 10pm',
  },
];

export const drugUnitOptions = [
  { value: '', label: 'Unit' },
  { value: 'mg', label: 'mg' },
  { value: 'g', label: 'g' },
  { value: 'kg', label: 'kg' },
  { value: 'ml', label: 'ml' },
  { value: 'L', label: 'L' },
  { value: 'cl', label: 'cl' },
  { value: 'spoon', label: 'spoon' },
  { value: 'mcg', label: 'mcg' },
  { value: '%', label: '%' },
  { value: 'µg', label: 'µg' },
  { value: 'mL', label: 'mL' },
  { value: 'IU', label: 'IU' },
  { value: 'Vector genomes', label: 'Vector genomes' },
  { value: 'units', label: 'units' },
  { value: 'mg per mL', label: 'mg per mL' },
  { value: 'Cells', label: 'Cells' },
  { value: 'Lf', label: 'Lf' },
  { value: 'mCi', label: 'mCi' },
  { value: 'per Kg', label: 'per Kg' },
];

export const drugTypeOptions = [
  { value: '', label: 'Select Drug Type' },
  { value: 'Regular', label: 'Regular' },
  { value: 'Prescription', label: 'Prescription' },
];
