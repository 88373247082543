import React from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

function facilityPopup({
  currentName,
  activateAccount,
  enablePaymentAccess,
  activateSubscription,
  recordPayment,
  // importDataset,
  facilityId,
  facilityInfo,
}) {
  const { name } = useSelector((state) => state.country);
  const history = useHistory();
  return (
    <div>
      <p
        style={{
          color: '#2254D3',
          fontStyle: 'italic',
        }}
      >
        <u>For {currentName}</u>
      </p>
      <p className="" onClick={activateAccount}>
        Activate/Disable account
      </p>
      <p className="">Message user</p>
      <p className="">Email user</p>
      <p className="">View billing history</p>
      {/* <p onClick={importDataset}>Import pharmacy dataset</p> */}
      <p
        onClick={() =>
          history.push({
            pathname: `/thc-admin/${name}/facility-dataset/${facilityId}`,
            state: facilityInfo,
          })
        }
      >
        View pharmacy dataset
      </p>
      <p className="">View practice license</p>
      <p className="">View subscriber summary</p>
      <p className="" onClick={enablePaymentAccess}>
        Mobile payment access ENABLED/DISABLED
      </p>
      <p className="">Delivery service ENABLED/DISABLED</p>
      <p className="">Select delivery handler SELF/THC/NONE</p>
      <p onClick={recordPayment}>Record payment and activate subscription</p>
      <p onClick={activateSubscription}>
        Deactivate/Activate Facility Subscription
      </p>
    </div>
  );
}

export default facilityPopup;
