import React, { useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';

const FormModal = (props) => {
  const container = useRef();
  const { handleModal, setIsEditing } = props;

  const outsideClick = useCallback(
    (e) => {
      if (container.current?.contains(e.target)) {
        // inside click
        return;
      }

      if (!handleModal) {
        return;
      }
      handleModal();
      if (setIsEditing) {
        setIsEditing(false);
      }
    },
    // eslint-disable-next-line
    [handleModal]
  );

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', outsideClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', outsideClick);
    };
  }, [outsideClick]);
  return (
    <StyledFormModal
      height={props.height}
      centered={props.centered}
      width={props.width}
    >
      <div className="form-section" ref={container}>
        {props.children}
      </div>
    </StyledFormModal>
  );
};

const StyledFormModal = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease all;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  scrollbar-width: 20px;
  scrollbar-color: #2254d3 #eee;
  .form-section {
    width: ${({ width }) => (width ? width : '40%')};
    background: #fff;
    border-radius: 5px;
    padding: 10px;
    height: ${({ height }) => (height ? height : 'fit-content')};
    overflow-y: scroll;
    scrollbar-width: 30px;
    scrollbar-color: #2254d3 #eee;
    animation: ${({ centered }) => (centered ? 'none' : 'appear .5s ease')};
    @keyframes appear {
      0% {
        opacity: 0;
        transform: translateX(-100px);
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    background: rgba(33, 51, 79, 0.44);
    .grey-background {
      display: none;
    }
    .form-section {
      max-width: 100%;
    }
  }
  @media only screen and (max-width: 505px) {
    .form-section {
      margin: 0 auto;
      width: 100vw;
    }
  }
`;
export default FormModal;
