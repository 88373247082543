import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import request, { headers } from '@request';
import { toast } from 'react-toastify';
import Button from '@components/Button/Button';
import ModalWrapper from '@components/modalComponents/NewModal';
import ModalTitleSection from '@components/modalComponents/FormTitleSection';
import TextAreaInput from '@components/formInputs/TextAreaInput';
import { useFetchDrugGenericName } from '@hooks/queries';
import Input from '@components/formInputs/FormInputComponent';
import SubmitSuccess from '@components/SubmitSuccess';
import { useFormik } from 'formik';
import {
  drugModeOptions,
  drugUnitOptions,
  drugTypeOptions,
} from '@utils/listingOptions/DrugOptions';
import Select from 'react-select';
import { whiteBackground } from '@styles/react-select-custom';
import SelectWrapper from '@components/formInputs/selectWrapper';

export default function CreateGenenericName({
  closeModal,
  currentData,
  createEndpoint,
  editEndpoint,
  formType,
}) {
  const [loading, setLoading] = useState(false);
  const { refetch } = useFetchDrugGenericName();

  const [submitted, setSubmitted] = useState(false);

  const initialValues = {
    name: '',
    formulary: '',
    category: '',
    mode: '',
    unit: '',
    strength: '',
    description: '',
    section: '',
    indication: '',
  };

  const submitResult = async (values) => {
    console.log('values', values);
    setLoading(true);
    const requestMethod = currentData?.id ? `patch` : 'post';
    try {
      await request[requestMethod](
        currentData?.id ? editEndpoint : createEndpoint,
        values,
        headers(localStorage.getItem('token'))
      );
      toast.success('Result parameter submitted');
      refetch();
      setSubmitted(true);
    } catch (err) {
      console.log('error', err.response);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: submitResult,
  });

  console.log('formik values', formik.values);

  const onLoad = () => {
    if (currentData) {
      const fields = Object.keys(initialValues);
      fields.forEach((field) => {
        formik.setFieldValue(field, currentData[field], false);
      });
    }
  };

  useEffect(() => {
    onLoad();
  }, [currentData]);

  return (
    <ModalWrapper>
      <ModalTitleSection
        onClick={closeModal}
        title={currentData ? `Edit new ${formType}` : `Create ${formType}`}
      />
      {submitted ? (
        <SubmitSuccess
          message={currentData ? 'Edited successfully' : 'Created successfully'}
        />
      ) : (
        <OrderDetailStyle>
          <form onSubmit={formik.handleSubmit}>
            <div className="tests mt-3 mb-3">
              <Input
                label="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                name="name"
              />

              <Input
                label="Formulary"
                value={formik.values.formulary}
                onChange={formik.handleChange}
                name="formulary"
              />
              <SelectWrapper label="Category">
                <Select
                  styles={whiteBackground}
                  options={drugTypeOptions}
                  value={drugTypeOptions?.find(
                    (item) => item.value === formik.values.category
                  )}
                  onChange={(e) =>
                    formik.setFieldValue('category', e.value, false)
                  }
                  name="category"
                />
              </SelectWrapper>
              <SelectWrapper label="Mode">
                <Select
                  styles={whiteBackground}
                  options={drugModeOptions}
                  value={drugModeOptions?.find(
                    (item) => item.value === formik.values.mode
                  )}
                  onChange={(e) => formik.setFieldValue('mode', e.value, false)}
                  name="mode"
                />
              </SelectWrapper>
              <SelectWrapper label="Unit">
                <Select
                  label="Unit"
                  styles={whiteBackground}
                  options={drugUnitOptions}
                  value={drugUnitOptions?.find(
                    (item) => item.value === formik.values.unit
                  )}
                  onChange={(e) => formik.setFieldValue('unit', e.value, false)}
                  name="unit"
                />
              </SelectWrapper>
              <Input
                label="Strength"
                value={formik.values.strength}
                onChange={formik.handleChange}
                name="strength"
              />
              <Input
                label="Indication"
                value={formik.values.indication}
                onChange={formik.handleChange}
                name="indication"
              />
              <Input
                label="Section"
                value={formik.values.section}
                onChange={formik.handleChange}
                name="section"
              />
            </div>
            <div>
              <TextAreaInput
                type="text"
                placeholder="Template note"
                rows="3"
                onChange={formik.handleChange}
                value={formik.values.description}
                name="description"
              />
            </div>

            <div className="w-100 save-btn">
              <Button
                loading={loading}
                width="200px"
                type="submit"
                blue
                disabled={!formik.values.name || formik.values.name.length < 3}
                onClick={submitResult}
              >
                Save
              </Button>
            </div>
          </form>
        </OrderDetailStyle>
      )}
    </ModalWrapper>
  );
}

const OrderDetailStyle = styled.div`
  width: 100%;
  input {
    padding: 10px 5px;
    margin-top: -5px;
    outline: none;
    border: none;
    border: 1px solid #2254d3;
    border-radius: 5px;
    font-size: 12px;
    width: 100%;
  }

  .tests {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .save-btn {
    margin-top: 12px;
  }
  .max-w {
    width: 200px;
  }
`;
