import BASE_URL, { headers } from '@request';
import { useQuery } from 'react-query';

const token = localStorage.getItem('token');
const fetchAllCountries = async () => {
  return await BASE_URL.get(
    `/utils/get-all-countries-in-platform/`,
    headers(token)
  );
};

export const useFetchAllCountries = () => {
  const data = useQuery(['get-all-countries-in-platform'], () =>
    fetchAllCountries()
  );
  return data;
};

const fetchActiveCountries = async () => {
  return await BASE_URL.get(`/utils/thc-get-active-countries/`, headers(token));
};

export const useFetchActiveCountries = () => {
  const data = useQuery(['active-countries'], () => fetchActiveCountries());
  return data;
};

const fetchFacility = async (facilityType) => {
  return await BASE_URL.get(`/utils/country/${facilityType}/`, headers(token));
};

export const useFetchFacility = (facilityType) => {
  const data = useQuery(['fetchFacility', facilityType], () =>
    fetchFacility(facilityType)
  );
  return data;
};

const fetchTHCStaff = async () => {
  return await BASE_URL.get(`/utils/thc-admins/get/`, headers(token));
};

export const useFetchTHCStaff = () => {
  const data = useQuery(['fetchthcstaff'], () => fetchTHCStaff());
  return data;
};

const getSubscriptions = async (facilityType) => {
  return await BASE_URL.get(
    `/billings/subscription/${facilityType}/`,
    headers(token)
  );
};

export const useGetSubscriptions = (facilityType) => {
  const data = useQuery(['getSubscriptions'], () =>
    getSubscriptions(facilityType)
  );
  return data;
};

const fetchTransaction = async (facilityType) => {
  return await BASE_URL.get(
    `utils/transaction-charge/${facilityType}/`,
    headers(token)
  );
};

export const useFetchTransaction = (facilityType) => {
  const data = useQuery(
    ['fetchTransaction', facilityType],
    () => fetchTransaction(facilityType),
    {
      enabled: !!facilityType,
    }
  );
  return data;
};

export const useFetchInvestigations = (facilityType) => {
  const urlEndpoint = async () => {
    const response = await BASE_URL.get(
      `utils/transaction-charge/${facilityType}/`,
      headers(token)
    );
    return response.data;
  };

  return useQuery(['useFetchInvestigations', facilityType], urlEndpoint);
};

export const useFetchPharmacyDataset = (
  facilityId,
  currentPage,
  searchKeyWord,
  url
) => {
  const urlEndpoint = async () => {
    const response = await BASE_URL.get(
      `${url}/?page=${currentPage}&search=${searchKeyWord}`,
      headers(token)
    );
    return response.data;
  };

  return useQuery(
    ['useFetchPharmacyDataset', facilityId, currentPage, searchKeyWord, url],
    urlEndpoint
  );
};

export const drugGenericName = async () => {
  return await BASE_URL.get(`/utils/drug-generic-name`);
};

export const useDrugGenericName = () => {
  const data = useQuery(['drugGenericName'], () => drugGenericName(), {
    retry: false,
    staleTime: 3000,
  });
  const drugOptions = data?.data?.data?.map((fa) => ({
    label: fa.name,
    value: fa.name,
  }));
  return drugOptions;
};

export const useFetchForum = () => {
  const urlEndpoint = async () => {
    const response = await BASE_URL.get(`rtc/list-forum/`);
    return response.data;
  };

  return useQuery(['useFetchForum'], urlEndpoint);
};

export const useFetchDrugGenericName = () => {
  const urlEndpoint = async () => {
    const response = await BASE_URL.get(`utils/drug-generic-name`);
    return response.data;
  };

  return useQuery(['useFetchDrugGenericName'], urlEndpoint);
};

export const useTreatmentCategory = () => {
  const urlEndpoint = async () => {
    const response = await BASE_URL.get(`utils/treatment-category`);
    return response.data;
  };

  return useQuery(['useFetchDrugGenericName'], urlEndpoint);
};

export const useFetchAddon = (facility_type) => {
  const urlEndpoint = async () => {
    const response = await BASE_URL.get(
      `utils/mgt-addons?facility_type=${facility_type}`,
      headers(token)
    );
    return response.data;
  };

  return useQuery(['useFetchAddon', facility_type], urlEndpoint);
};

export const useLoginTracker = (currentPage) => {
  const urlEndpoint = async () => {
    const response = await BASE_URL.get(
      `main/auth/login-events/?page=${currentPage}`
    );
    return response.data;
  };

  return useQuery(['useLoginTracker', currentPage], urlEndpoint);
};
