import React, { useState } from 'react';
import TabNavigation from '@components/TabNavigation';
import styled from 'styled-components';
import Header from '../../../common/Header';
import Layout from '../../dashboardSidebar/Layout';
import DrugGeneric from './DrugGenericName';
import TreatmentCategory from './TreatmentCategory';
import GeneralDataset from './GeneralDataset';

const BillingComponent = () => {
  const [activeTab, setActiveTab] = useState('Drug Generic');
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const tabs = [
    {
      id: 'Drug Generic',
      label: 'Drug Generic',
      content: <DrugGeneric />,
    },
    {
      id: 'Treatment Category',
      label: 'Treatment Category',
      content: <TreatmentCategory />,
    },
    {
      id: 'dataset',
      label: 'Dataset',
      content: <GeneralDataset />,
    },
  ];

  return (
    <Layout>
      <Header heading={`Drugs | ${activeTab}`} />
      <BillingWrapper>
        <TabNavigation
          tabs={tabs}
          activeTab={activeTab}
          onTabClick={handleTabClick}
        />
      </BillingWrapper>
    </Layout>
  );
};

export default BillingComponent;

const BillingWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;
