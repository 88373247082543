import React, { useState } from 'react';
import Button from '@components/Button/Button';

import { whiteBackground } from '@styles/react-select-custom';
import Select from 'react-select';
import ModalWrapper from '@components/modalComponents/NewModal';
import FormTitleSection from '@components/modalComponents/FormTitleSection';
import { toast } from 'react-toastify';
import request, { headers } from '@request';
import Input from '@components/formInputs/FormInputComponent/';
import SelectWrapper from '@components/formInputs/selectWrapper';
import { useFetchAddon } from '@hooks/queries';

export default function ActivateAddon({
  facilityName,
  facilityId,

  setCreateAddon,
  createAddon,
  facilityType,
}) {
  const token = localStorage.getItem('token');
  const [name, setName] = useState(null);
  const [payment_reference, setPayment_reference] = useState('');
  const [date_purchased, setDate_purchased] = useState('');
  const [number_of_days, setNumber_of_days] = useState(0);
  const [loading, setLoading] = useState(false);
  const { data } = useFetchAddon(facilityType);

  const addonOptions = data?.map((item) => {
    return {
      label: item.label,
      value: item.name,
    };
  });

  const submit = async (e) => {
    e.preventDefault();

    const payload = {
      name,
      payment_reference,
      date_purchased,
      number_of_days,
    };
    console.log('payload', payload);

    setLoading(true);
    try {
      await request.post(
        `utils/addon-activate/${facilityId}/`,
        payload,
        headers(token)
      );

      toast.success(`Addon activated for ${facilityName}`);
    } catch (error) {
      toast.error(error?.response?.data?.error || 'Error creating addon');
      console.log('error', error?.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalWrapper>
      <FormTitleSection
        title={`Activate Addon for ${facilityName}`}
        onClick={() => setCreateAddon(!createAddon)}
      />
      <form className="">
        <div className="mb-3">
          <SelectWrapper label="Select Addon">
            <Select
              options={addonOptions}
              placeholder="Addon"
              styles={whiteBackground}
              value={addonOptions?.find((item) => item.value === name)}
              onChange={(e) => setName(e.value)}
            />
          </SelectWrapper>
        </div>

        <div className="mb-3">
          <Input
            label="Payment reference"
            type="text"
            value={payment_reference}
            onChange={(e) => setPayment_reference(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <Input
            label="Date purchase"
            type="date"
            value={date_purchased}
            onChange={(e) => setDate_purchased(e.target.value)}
          />
        </div>

        <div className="mb-3">
          <Input
            label="Number of days"
            type="number"
            value={number_of_days}
            onChange={(e) => setNumber_of_days(e.target.value)}
          />
        </div>

        <div className="mt-3">
          <Button loading={loading} onClick={submit} type="submit">
            Save
          </Button>
        </div>
      </form>
    </ModalWrapper>
  );
}
