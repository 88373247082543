import React, { useState, useEffect, useRef } from 'react';
import '@styles/Accordion.css';
import styled from 'styled-components';
import '@styles/table.style.css';
import { useLoginTracker } from '@hooks/queries';
import Pagination from '@utils/pagination';
import { PAGESIZE } from '@constant/pageSize';
import Checkbox from '@components/formInputs/checkbox';
import moment from 'moment';
import Header from '../../common/Header';
import Layout from '../dashboardSidebar/Layout';
import TableLoader from '@components/TableLoader';

export default function FacilityDataset() {
  const ref = useRef(null);
  const [idOfTable, setIdOfTable] = useState(-1);
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading } = useLoginTracker(currentPage);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIdOfTable(-1);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  //   eslint-disable-next-line
  // const [currData, setCurrData] = useState(null);
  // console.log('curent data', currData);

  const showModal = (index) => {
    setIdOfTable(index);
  };

  return (
    <Layout>
      <Header heading="Login Tracker" />
      <AccordionWrapper>
        <div className="d-flex gap-1"></div>

        {isLoading ? (
          <TableLoader rows={5} columns={5} />
        ) : (
          <div className="">
            <table>
              <thead>
                <tr>
                  <th>
                    <Checkbox />
                  </th>
                  <th>Name</th>
                  <th>Facility</th>
                  <th>Role</th>
                  <th>Country</th>
                  <th>Facility Type</th>
                  <th>Platorm</th>
                  <th>Date/Time Login</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.results?.length == 0 && <p>No dataset</p>}
                {data?.results?.length > 0 &&
                  data?.results?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <Checkbox />
                      </td>

                      <td>
                        {item?.user?.first_name
                          ? `${item?.user?.first_name} ${item?.user?.last_name}`
                          : 'Admin(Account Creator)'}
                      </td>
                      <td>{item?.user?.practice_name}</td>
                      <td>{item?.user?.role}</td>
                      <td>{item?.user?.country}</td>
                      <td>{item?.user?.user_type}</td>
                      <td>{item?.platform}</td>
                      <td>
                        {moment(item?.login_time).format(
                          'DD MMM, yyyy/hh:mm a'
                        )}
                      </td>

                      <td
                        id={index}
                        className="three-dots"
                        onClick={() => showModal(index)}
                      >
                        ...
                        {index === idOfTable ? (
                          <div ref={ref} className="popup">
                            <p>Do something</p>
                          </div>
                        ) : null}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="pagination-container">
              {data && data?.results?.length > 0 && (
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={data?.count}
                  pageSize={PAGESIZE}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              )}
            </div>
          </div>
        )}
      </AccordionWrapper>
    </Layout>
  );
}

const AccordionWrapper = styled.div`
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40vh;
  }
`;
