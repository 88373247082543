import React, { useState, useEffect } from 'react';
import BASE_URL, { headers } from '@request';
import styled from 'styled-components';
import ModalWrapper from '@components/modalComponents/NewModal';
import ModalTitle from '@components/modalComponents/FormTitleSection';
import { toast } from 'react-toastify';
import Checkbox from '@components/formInputs/checkbox';

export default function ActivateFacilitySubscription({
  currentAccount,
  onClick,
  currentState,
  subscriptionId,
  subscription,
}) {
  const [checkState, setCheckState] = useState(null);
  useEffect(() => {
    setCheckState(currentState);
  }, [currentState]);

  async function save() {
    const token = localStorage.getItem('token');
    try {
      const res = await BASE_URL.post(
        `utils/thc-activate-deactivate-subscription/${subscriptionId}/`,
        { subscription_status: !checkState },
        headers(token)
      );
      if (res.status === 200) {
        toast.success('Facility payment status update successfully');
      }
    } catch (err) {
      console.log('error', err);
    }
  }

  return (
    <ModalWrapper>
      <AccountModalStyle>
        <ModalTitle
          name={currentAccount}
          onClick={onClick}
          title={`Activate/Deativate Subscription`}
        />
        {subscription ? (
          <Checkbox
            onChangeFunction={() => {
              setCheckState(!checkState);
              save();
            }}
            isChecked={checkState}
            checked={checkState}
            label={'Active Subscription'}
          />
        ) : (
          <p>Facility does not have a valid subscription</p>
        )}
      </AccountModalStyle>
    </ModalWrapper>
  );
}

const AccountModalStyle = styled.div``;
