import React, { useState, useEffect } from 'react';
import Button from '@components/Button/Button';

import { whiteBackground } from '@styles/react-select-custom';
import Select from 'react-select';
import ModalWrapper from '@components/modalComponents/NewModal';
import FormTitleSection from '@components/modalComponents/FormTitleSection';
import { toast } from 'react-toastify';
import request, { headers } from '@request';
import TextAreaInput from '@components/formInputs/TextAreaInput/';
import Input from '@components/formInputs/FormInputComponent/';
import SelectWrapper from '@components/formInputs/selectWrapper';

const ADDONS = [
  { label: 'Ward and Rooms', value: 'WARDANDROOMS' },
  { label: 'Diagnostic and Labs', value: 'DIAGNOSTICSANDLAB' },
  { label: 'Diagnostic Result Template', value: 'DIAGNOSTICRESULTTEMPLATE' },
  { label: 'Dicom Viewer', value: 'DICOMVIEWER' },
  { label: 'Pharmacy', value: 'PHARMACY' },
  { label: 'Inventory', value: 'INVENTORY' },
  { label: 'Status Notification', value: 'STATUSNOTIFICATION' },
  {
    label: 'Drug List and Interaction Guide',
    value: 'DRUGLISTANDINTERACTIONGUIDE',
  },

  { label: 'Theatre', value: 'THEATRE' },
  { label: 'Examination template', value: 'EXAMINATIONTEMPLATES' },
  { label: 'Inspection record', value: 'INSPECTIONRECORDS' },
  { label: 'H.R', value: 'HR' },
  { label: 'Analytic and Statistics', value: 'ANALYTICSANDSTATISTICS' },
  { label: 'System Logs', value: 'SYSTEMLOGS' },
];

const FACILITY = [
  { label: 'Hospital', value: 'HOSPITAL' },
  { label: 'Diagnostic', value: 'DIAGNOSTIC' },
  { label: 'Consultant', value: 'CONSULTANT' },
  { label: 'Pharmacy', value: 'PHARMACY' },
];

export default function CreateNew({
  currentData,
  refetch,
  setCreateAddon,
  createAddon,
}) {
  const token = localStorage.getItem('token');
  const [name, setName] = useState(null);
  const [facility_type, setFacility_type] = useState('');
  const [amount_yearly, setAmount_yearly] = useState(null);
  const [amount_monthly, setAmount_monthly] = useState(null);
  const [label, setLabel] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);

  const submit = async (e) => {
    e.preventDefault();

    const payload = {
      name,
      label,
      facility_type,
      amount_yearly,
      amount_monthly,
      description,
    };
    console.log('payload', payload);

    setLoading(true);
    const requestMethod = currentData ? 'patch' : 'post';
    try {
      const res = await request[requestMethod](
        currentData
          ? `utils/addons/${currentData?.id}/`
          : `utils/addons-create/`,
        payload,
        headers(token)
      );
      console.log('response', res.status);
      refetch();
      toast.success(`Service added`);
    } catch (error) {
      toast.error(`Error creating service`, error);
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  function onLoad() {
    if (currentData) {
      setName(currentData.name);
      setFacility_type(currentData.facility_type);
      setAmount_yearly(currentData.amount_yearly);
      setAmount_monthly(currentData.amount_monthly);
      setDescription(currentData.description);
      setLabel(currentData.label);
    }
  }

  useEffect(() => {
    onLoad();
  }, [currentData]);

  return (
    <ModalWrapper>
      <FormTitleSection
        title={currentData ? 'Edit Addon' : 'Create New Addon'}
        onClick={() => setCreateAddon(!createAddon)}
      />
      <form className="">
        <div className="mb-3">
          <SelectWrapper label="Select Addon">
            <Select
              options={ADDONS}
              placeholder="Addon"
              styles={whiteBackground}
              value={ADDONS?.find((item) => item.value === name)}
              onChange={(e) => setName(e.value)}
            />
          </SelectWrapper>
        </div>

        <div className="mb-3">
          <SelectWrapper label="Select Facility Type">
            <Select
              options={FACILITY}
              placeholder="Addon"
              styles={whiteBackground}
              value={FACILITY?.find((item) => item.value === facility_type)}
              onChange={(e) => setFacility_type(e.value)}
            />
          </SelectWrapper>
        </div>

        <div className="mb-3">
          <Input
            label="Label"
            type="text"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />
        </div>

        <div className="mb-3">
          <Input
            label="Yearly amount"
            type="number"
            value={amount_yearly}
            onChange={(e) => setAmount_yearly(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <Input
            label="Monthly amount"
            type="number"
            value={amount_monthly}
            onChange={(e) => setAmount_monthly(e.target.value)}
          />
        </div>

        <div className="mb-3">
          <SelectWrapper label="Description">
            {' '}
            <TextAreaInput
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Service description"
              border="#2254D3"
            />
          </SelectWrapper>
        </div>
        <div className="mt-3">
          <Button loading={loading} onClick={submit} type="submit">
            Save
          </Button>
        </div>
      </form>
    </ModalWrapper>
  );
}
