import React, { useState } from 'react';
import styled from 'styled-components';
import request, { headers } from '@request';
import { toast } from 'react-toastify';
import AddMoreButton from '@components/Button/AddMoreButton';
import Button from '@components/Button/Button';
import ModalWrapper from '@components/modalComponents/NewModal';
import ModalTitleSection from '@components/modalComponents/FormTitleSection';
import { whiteBackground } from '@styles/react-select-custom';
import Select from 'react-select';
// import { useFetchinvestigatioResultParameter } from '@hooks/queries';
import { SpecimenType } from '@utils/listingOptions/SpecimenType';
import { useFetchInvestigation } from '@hooks/investigations';
import { units, parameters } from '@utils/listingOptions/parameter';
import SelectWrapper from '@components/formInputs/selectWrapper';
import TextAreaInput from '@components/formInputs/TextAreaInput';
import { useFetchInvestigationResultParameter } from '@hooks/investigations';

const CreateResultTemplateForm = ({ closeModal }) => {
  const [loading, setLoading] = useState(false);
  const { investigationId } = useFetchInvestigation();
  const [investigationName, setInvestigationName] = useState(null);
  // const [analyser, setAnalyser] = useState({ label: '', value: '' });
  const [specimen, setSpecimen] = useState({ label: '', value: '' });
  const [description, setDescription] = useState('');
  const { refetch } = useFetchInvestigationResultParameter();

  const [resultParams, setResultParams] = useState([
    {
      name: '',
      value: '',
      range_from: '',
      range_to: '',
      unit: '',
      note: '',
      subscript: '',
      superscript: '',
    },
  ]);

  const addMoreResultParam = () => {
    let newObject = {
      name: '',
      range_from: '',
      range_to: '',
      unit: '',
      note: '',
      subscript: '',
      superscript: '',
    };
    // Create a new array with the existing resultParams and the new object
    let resultParamsCopy = [...resultParams, newObject];
    setResultParams(resultParamsCopy);
  };

  // console.log('resultParams', resultParams);
  const submitResult = async () => {
    setLoading(true);
    const payload = {
      investigation_id: investigationName.value,
      result_params: resultParams,
      description: description,
      specimen: specimen.value,
      // analyser: analyser.value,
    };
    try {
      await request.post(
        `utils/result-parameter/create/`,
        payload,
        headers(localStorage.getItem('token'))
      );
      toast.success('Result parameter submitted');
      refetch();
    } catch (err) {
      console.log('error', err.response);
      // toast.error(err.response.data.data.errors.message);
    }
    setLoading(false);
  };

  const onChangeHandler = (e, i) => {
    const { name, value } = e.target;
    const copyResultParams = [...resultParams];
    copyResultParams[i][name] = value;
    setResultParams(copyResultParams);
  };

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestionsArray, setShowSuggestionsArray] = useState(
    Array(resultParams.length).fill(false)
  );

  const handleInputChange = (e, i) => {
    const { name, value } = e.target;
    const copyResultParams = [...resultParams];
    copyResultParams[i][name] = value;
    setResultParams(copyResultParams);
    // name === 'name' ? (arr = parameters) : (arr = units);

    const filtered = parameters.filter((suggestion) =>
      suggestion.toLowerCase().startsWith(value.toLowerCase())
    );

    const newShowSuggestionsArray = [...showSuggestionsArray];
    newShowSuggestionsArray[i] = value.length > 0;
    setFilteredSuggestions(filtered);
    setShowSuggestionsArray(newShowSuggestionsArray);
  };

  const handleSuggestionClick = (suggestion, index) => {
    const copyResultParams = [...resultParams];
    copyResultParams[index]['name'] = suggestion;
    setResultParams(copyResultParams);

    const newShowSuggestionsArray = [...showSuggestionsArray];
    newShowSuggestionsArray[index] = false;
    setShowSuggestionsArray(newShowSuggestionsArray);
  };

  // unit onchange handler and suggestion functions
  const [unitSuggestion, setUnitSuggestions] = useState([]);
  const [showUnitSuggestionsArray, setShowUnitSuggestionsArray] = useState(
    Array(resultParams.length).fill(false)
  );
  const handleUnitChange = (e, i) => {
    const { name, value } = e.target;
    const copyResultParams = [...resultParams];
    copyResultParams[i][name] = value;
    setResultParams(copyResultParams);

    const filtered = units.filter((suggestion) =>
      suggestion.toLowerCase().startsWith(value.toLowerCase())
    );

    const newShowSuggestionsArray = [...showUnitSuggestionsArray];
    newShowSuggestionsArray[i] = value.length > 0;
    setUnitSuggestions(filtered);
    setShowUnitSuggestionsArray(newShowSuggestionsArray);
  };

  const handleUnitSuggestionClick = (suggestion, index) => {
    const copyResultParams = [...resultParams];
    copyResultParams[index]['unit'] = suggestion;
    setResultParams(copyResultParams);

    const newShowSuggestionsArray = [...showSuggestionsArray];
    newShowSuggestionsArray[index] = false;
    setShowUnitSuggestionsArray(newShowSuggestionsArray);
  };

  return (
    <ModalWrapper width="60%">
      <ModalTitleSection onClick={closeModal} title="Create Result Template" />
      <OrderDetailStyle>
        <div className="tests mt-3 mb-3">
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '20px',
              alignItems: 'center',
            }}
            className="mb-2"
          >
            <SelectWrapper label="Test">
              <Select
                placeholder={'Select test name'}
                styles={whiteBackground}
                options={investigationId}
                value={investigationName}
                onChange={(e) => setInvestigationName(e)}
              />
            </SelectWrapper>
            <SelectWrapper label="Specimen Type">
              <Select
                placeholder={'Select test name'}
                styles={whiteBackground}
                options={SpecimenType}
                value={specimen}
                onChange={(e) => setSpecimen(e)}
              />
            </SelectWrapper>
          </div>

          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '3fr 1fr 1fr',
              gap: '20px',
            }}
          >
            <div></div>
            <div></div>
            <div style={{ width: '210px' }}>
              <AddMoreButton
                title="Add another value"
                display
                height="39px"
                onClick={addMoreResultParam}
                backgroundColor="#DFE8FC"
                borderRadius="50px"
                sBackgroundColor="#2254D3"
                sBorderRadius="50%"
                color="#2254D3"
              />
            </div>
          </div>
          <div
            className="w-full"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            {resultParams?.map(
              ({ name, unit, range_from, range_to, flag, note }, index) => (
                <div className="row-wrapper" key={index}>
                  <SelectWrapper label={'Parameter'}>
                    <AutocompleteContainer show={showSuggestionsArray[index]}>
                      <input
                        type="text"
                        value={name}
                        name="name"
                        placeholder="Search and select parameter"
                        onChange={(e) => handleInputChange(e, index)}
                      />
                      <div className="suggestionList">
                        {filteredSuggestions.map((suggestion, idx) => (
                          <div
                            className="suggestionItem"
                            key={idx}
                            onClick={() =>
                              handleSuggestionClick(suggestion, index)
                            }
                          >
                            {suggestion}
                          </div>
                        ))}
                      </div>
                    </AutocompleteContainer>
                  </SelectWrapper>

                  {/* unit */}
                  <SelectWrapper label={'Unit'}>
                    <AutocompleteContainer
                      show={showUnitSuggestionsArray[index]}
                    >
                      <input
                        type="text"
                        value={unit}
                        name="unit"
                        placeholder="Select"
                        onChange={(e) => handleUnitChange(e, index)}
                      />
                      <div className="suggestionList">
                        {unitSuggestion.map((suggestion, idx) => (
                          <div
                            className="suggestionItem"
                            key={idx}
                            onClick={() =>
                              handleUnitSuggestionClick(suggestion, index)
                            }
                          >
                            {suggestion}
                          </div>
                        ))}
                      </div>
                    </AutocompleteContainer>
                  </SelectWrapper>

                  <SelectWrapper label={'Reference Range'}>
                    <div className="d-flex">
                      <input
                        type="text"
                        value={range_from}
                        name={'range_from'}
                        onChange={(e) => onChangeHandler(e, index)}
                        className="range"
                        placeholder="Enter"
                      />
                      <p>to</p>
                      <input
                        type="text"
                        value={range_to}
                        name={'range_to'}
                        onChange={(e) => onChangeHandler(e, index)}
                        className="range"
                        placeholder="Enter"
                      />
                    </div>
                  </SelectWrapper>

                  {/* <SelectWrapper label="Flag"> */}
                  <input
                    type="hidden"
                    value={flag}
                    name={'flag'}
                    className="flag"
                    onChange={(e) => onChangeHandler(e, index)}
                  />
                  {/* </SelectWrapper> */}
                  <SelectWrapper label="Note">
                    <input
                      type="text"
                      value={note}
                      name={'note'}
                      placeholder="Parameter note/comment"
                      onChange={(e) => onChangeHandler(e, index)}
                    />
                  </SelectWrapper>
                </div>
              )
            )}
          </div>
        </div>
        <div>
          <TextAreaInput
            type="text"
            name={'note'}
            placeholder="Template note"
            rows="3"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />
        </div>

        <div className="w-100 save-btn">
          <Button
            loading={loading}
            width="200px"
            type="submit"
            blue
            onClick={submitResult}
          >
            Save
          </Button>
        </div>
      </OrderDetailStyle>
    </ModalWrapper>
  );
};

const OrderDetailStyle = styled.div`
  width: 100%;
  input {
    padding: 10px 5px;
    margin-top: -5px;
    outline: none;
    border: none;
    border: 1px solid #2254d3;
    border-radius: 5px;
    font-size: 12px;
    width: 100%;
  }
  .range {
    width: 70px;
    text-align: center;
  }
  .flag {
    width: 100px;
  }

  .tests {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .result-param {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .row-wrapper {
    width: 100%;
    gap: 5px;
    display: grid;
    grid-template-columns: 2fr 0.5fr 1fr 1.5fr;
  }

  .save-btn {
    margin-top: 12px;
  }
  .max-w {
    width: 200px;
  }
`;

const AutocompleteContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  .suggestionList {
    width: 100%;
    background-color: #f3f4f8;
    position: absolute;
    list-style-type: none;
    margin: 0;
    padding: 0;
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
    display: ${(props) => (props.show ? 'block' : 'none')};
  }
  input {
    display: inline-block;
    // font-size: 1.6rem;
    background: white;
    -webkit-appearance: none;
    width: 100%;
    border: none;
    height: ${(props) => (props.height ? props.height : '37px')};
    /* padding-bottom: 0rem;
    padding-left: 1rem;
    padding-right: 1rem; */
    /* border: 1px solid #dddddf !important; */
    border-color: ${({ borderColor }) =>
      borderColor ? borderColor : '#2254D3'};
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    font-size: 12px;
  }
  .suggestionItem {
    padding: 5px;
    cursor: pointer;

    color: black;
    &:hover {
      background-color: #2254d3;
      color: #fff;
    }
  }
  .form-error {
    color: red;
    font-size: 12px;
  }
`;

export default CreateResultTemplateForm;
